// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import GoToInstall from "../../../components/widgets/goToInstall"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="_compact">
                <MercadoLibreLogo />
                <h2>Facturación electrónica en MercadoLibre</h2>
                <p className="intro">
                Permite a tus clientes obtener su factura por mensaje sin invertir tu tiempo.
                </p>
                <h3>
                    Funcionalidades
                </h3>
                <ul>
                    <li>
                        <p>
                            <b>Facturación simplificada para tus clientes:</b> Nuestra app envía un link para facturar a tu cliente por mensaje, por lo que no tendrás que ocuparte de 
                            realizar la facturación manualmente.<br />
                           <span className="small quote"> Guardamos y relacionamos el RFC de tus compradores entre todas las tiendas, por lo que si alguna vez facturaron con nosotros, <b>podrán obtener su factura en 2 clicks</b></span>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación global automática:</b> Podrás contar con tu factura global a fin de mes o cada semana según prefieras, facturando los pedidos que no fueron facturados.<br />
                            <span className="small quote"> Conoce más acerca de la facturación global automática <Link to="./facturacion-global">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Fácil configuración de tus productos:</b> Configura las claves de tus productos por categoría completa o usa una sola clave predeterminada para todos tus productos. <br />
                            <span className="small">Nota: No es necesario configurar cada uno de tus productos, solamente es aecuado configurar categorías muy diferentes al resto de tu inventario.
                            </span>
                        
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Define Kits de productos:</b> Podrás establecer el contenido de listados que vendas como Kits y cada una de sus partes. <br />
                            <span className="small">Nota: Cada producto dentro de un kit podrá contar con sus propias claves, cantidades y precios.
                            </span>                        
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Fácil configuración de impuestos:</b> De manera predeterminada, todos los productos cuentan con 16% de IVA, pero podrás definirlos como excentos o incluso agregar IEPS si lo deseas. <br />                        
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación global manual:</b>  (facturas mostrador no reclamadas) Adicionalmente al proceso automático, puedes optar por realizar las facturas globales manualmente. <br />
                            En ella, podrás seleccionar todo el mes en curso, o inlcuso el mes anterior. <br />
                            <span className="small">Nota: El proceso de facturación global manual puede operar con decenas de miles de órdenes. </span>
                        </p>
                    </li>

                </ul>
                <GoToInstall href="" />
                <div className="sectionNote">
                    <p>
                        Tus cerificados están seguros con nosotros ya que <b>no guardamos</b> estos archivos, solo obtenemos la
información que requerimos, la encriptamos y posteriormente los descartamos.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default ShopifyLanding
