import React from 'react';

import './goToInstall.scss'

interface GoToInstallProps {
    href:string;
}

const GoToInstall: React.FC<GoToInstallProps> = ({href}) => {
    return (
        <h4 className="_goToInstall">
            Comienza tu proceso de integración <a href={href}>aquí</a> <br />
            <span>ó revisa las secciones en la barra izquierda</span>
        </h4>
    )
}

export default GoToInstall;